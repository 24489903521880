import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  plugins: [createPersistedState()],
  state: {
    apps: [],
  },
  mutations: {
    SET_APPS_INFO: (state, appsList) => {
      state.apps = appsList;
    },
  },
  actions: {
    addAppsInfo: ({ commit}, { appsList }) => {
      commit('SET_APPS_INFO', appsList);
    },
  },
  getters: {
    getAppsInfo: state => {
      return state.apps;
    },
    getAppInfoByName: (state) => (appName) => {
      console.log("This list of apps", state.apps)
      if(!state.apps) {
        console.log("no apps loaded")
        return null
      }
      return state.apps.find(item => {
        console.log(item.name.replace(/\s/g, "-"), appName)
        return item.name.replace(/\s/g, "-") === appName
      });
    },
  },
  modules: {
  }
})
