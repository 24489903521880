<template lang="pug">
  .appsList
    div#filters
      b-row.px-3(align-v="center")
        b-col(sm="4" lg="4" xl="4").text-left.p-3
          b-input(type="text" v-model="search" placeholder="Search apps")
        b-col(sm="8" lg="8" xl="8").text-rightp-3
          b-button(v-b-toggle.filters).float-right.buttonFilter Show filters

          b-sidebar(id="filters" title="Filters" right shadow)
            div(v-for="filter in filters" role="tablist").px-4
              b-row(v-b-toggle="filter.key").py-2
                b-col(cols="10")
                  p.sideBar {{filter.name.toUpperCase()}}
                b-col(cols="2")
                  b-icon(icon="chevron-up" size="sm")

              b-collapse(:id="filter.key" role="tabpanel")
                b-form-checkbox-group(v-model="filter.selected", :options="filter.options" stacked).m-2.mb-3

    br
    div.my-grid
      b-card-group(v-for="app in filteredApps.slice(currentPage*6,6+currentPage*6)" :key="app.name").p-2
        b-card(no-body style=" min-height: fit-content").overflow-hidden
          b-row(no-gutters).h-100
            b-col(md="6").p-3.align-content-center
              b-img(fluid center :src="'./images/'+app.imageName" alt="Image" style="max-height: fit-content;").rounded-0
            b-col(md="6")
              b-card-body(:title="app.name").d-flex.flex-column.h-100
                b-card-text.h-100
                  p {{app.shortDescription | snippet}}
                b-card-footer.pt-0.card-footer
                  a.link(type="button" @click="moreInfo(app)").mt-auto.align-self-end.pb-0 More info >>
    div(class="nav justify-content-center")
      span.m-2
        a.page(@click="currentPage=0" v-if="currentPage>0") <<
      span.m-2
        a.page(@click="currentPage=currentPage-1" v-if="currentPage>0") <
      span.m-2(style="font-weight:bold") {{currentPage+1}}
      span.m-2
        a.page(@click="currentPage=currentPage+1" v-if="currentPage<pages") >
      span.m-2
        a.page(@click="currentPage=pages" v-if="currentPage<pages") >>

</template>

<script>
  import Header from "./Header";

  export default {
    name: 'AppsList',
    components: {Header},
    data(){
      return{
        appsInfo: [],
        search: "",
        currentPage: 0,
        filters: [
          {
            name: "license type",
            key: "licenseType",
            selected: [],
            options: [
              { text: 'Public', value: 'public'},
              { text: 'Private', value: 'private'}
              ]
          },
          {
            name: "functionality",
            key: "functionality",
            selected: [],
            options: [
              { text: 'Control', value: 'control' },
              { text: 'Decision support', value: 'decision support' },
              { text: 'Design', value: 'design' },
              { text: 'Planning', value: 'planning' },
              { text: 'Maintenance', value: 'maintenance' },
              { text: 'Management', value: 'management' },
              { text: 'Modelling', value: 'modelling'},
              { text: 'Operation', value: 'operation' },
              { text: 'Optimization', value: 'optimization' },
              { text: 'Selection', value: 'selection' }
            ]
          },
          {
            name: "topic",
            key: "topic",
            selected: [],
            options: [
              { text: 'Aeration', value: 'aeration' },
              { text: 'Corrosion', value: 'corrosion' },
              { text: 'Cost', value: 'cost' },
              { text: 'Ecology', value: 'ecology'},
              { text: 'Epidemiology', value: 'epidemiology'},
              { text: 'GHG', value: 'GHG' },
              { text: 'Membranes', value: 'membranes' },
              { text: 'NBS', value: 'NBS' },
              { text: 'Nitrates', value: 'nitrates' },
              { text: 'Odours', value: 'odours' },
              { text: 'Pollution', value: 'pollution' },
            ]
          },
          {
            name: "urban water cycle",
            key: "urbanWaterCycle",
            selected: [],
            options: [
              { text: 'River', value: 'river' },
              { text: 'Collection', value: 'collection' },
              { text: 'Treatment', value: 'treatment' },
              { text: 'Distribution', value: 'distribution' },
              { text: 'Drinking water', value: 'drinking water' },
              { text: 'Sewer', value: 'sewer' },
              { text: 'Reuse', value: 'reuse' }

            ]
          },
        ]
      }
    },
    computed: {
      filteredApps: function(){

        let _this = this
        let lowerSearch = _this.search.toLowerCase()

        return _this.appsInfo.filter((app) => {
          let nameLower = app.name.toLowerCase()
          let descriptionLower = app.shortDescription.toLowerCase()

          let searchBox = nameLower.match(lowerSearch) || descriptionLower.match(lowerSearch)

          let filters = true
          _this.filters.forEach(filter => {
            let aux = (filter.selected.length === 0) || (filter.selected.filter(x => app.filters[filter.name].includes(x)).length > 0);
            filters = filters && aux;
          })
          return searchBox && filters
        });
      },
      pages: function (){
        //console.log(Math.floor(this.filteredApps.length/6));
        return Math.floor(this.filteredApps.length/6);
      }
    },
    mounted: function () {
      //let _this = this;
      //_this.appsInfo = _this.$store.getters.getAppsInfo;
      this.renderApps()
      // setTimeout(function(){
      //   console.log("current page= "+this.currentPage)
      //   console.log("pages= "+this.pages)
      //   if (this.currentPage < 3){
      //     this.currentPage = this.currentPage + 1;
      //   }
      //   else {
      //     this.currentPage = 0;
      //   }
      // }, 1000)
    },
    methods: {
      moreInfo: function (currentApp) {
        //let _this = this;
        //_this.$store.dispatch('setCurrentApp', {currentApp});
        let appName = currentApp.name.replace(/\s/g, "-"); //replace all whitespaces with '-'
        const path = '/'+appName;
        if(this.$route.path !== path)
          this.$router.push({ name: 'appInfo', params: { appName: appName }});
      },
      renderApps: function() {
        this.appsInfo = this.$store.getters.getAppsInfo;

      }
    },
    watch: {
      '$store.state.apps': function () {
        this.renderApps()
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .appsList {
    color: var(--text-main-color);
    line-height: normal;
  }

  #filters {
    background-color: var(--dark-gray);
  }

  p.sideBar {
    font-weight: bold;
  }


  a.link, a.link[type=button] {
    color: var(--blue-icra);
    align-self: end;
    float: right;
    padding-bottom: 1rem;
  }

  a.link:hover, a.link[type=button]:hover {
    color: var(--orange-icra);
    align-self: center;
    text-decoration: none;
    font-weight: bold;
  }

  a.page, a.page[type=button] {
    color: var(--text-nav-color);
      font-weight: bold;
      cursor: pointer;
  }

  a.page:hover, a.link[type=button]:hover {
    color: var(--blue-icra);
    text-decoration: none;
  }

  a.page.active, a.page.active[type=button] {
    color: var(--blue-icra);
    font-weight: bold;
  }

  div.row.collapsed .b-icon.bi {
    transform: rotate(180deg);
  }

  .buttonFilter {
    color: #fff;
    background-color: var(--blue-icra);
    border-color: var(--blue-icra);
    font-weight: var(--bold-text);
    font-size: small;
    overflow: hidden;
    min-width: fit-content;
  }

  .buttonFilter:hover {
    background-color: var(--blue-icra-hovered);
  }

  .buttonFilter:checked {
    background-color: var(--orange-icra);
  }

  .card-footer {
    background: transparent;
    border-top: 0px;
  }

  .my-grid {
    display: grid;
    justify-items: center;
    grid-template-columns: 50% 50%;
  }

  .my-grid > * {
    width: 100%;
  }

  .card-title {
    font-weight: bold;
  }

</style>
