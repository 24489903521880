import { render, staticRenderFns } from "./AppsList.vue?vue&type=template&id=f52c2a1e&scoped=true&lang=pug&"
import script from "./AppsList.vue?vue&type=script&lang=js&"
export * from "./AppsList.vue?vue&type=script&lang=js&"
import style0 from "./AppsList.vue?vue&type=style&index=0&id=f52c2a1e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f52c2a1e",
  null
  
)

export default component.exports