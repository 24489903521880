<template lang="pug">
  div.home
    div#presentation
      h1 IT ICRA Lab
      p ITlab ICRA is the web portal that gathers together the IT solutions developed in research and innovation projects with ICRA participation. The web portal provides a description of each of the solutions and gives credit to the researchers and computer engineers involved. It allows potential users access the solutions and even their source code. The ITlab has been promoted by Dr. Lluís Corominas and Prof. Joaquim Comas and it is maintained by the team of computer engineers involved in the development of the IT solutions.
      b-dropdown-divider.line.py-2

    AppsList
</template>

<script>
  // @ is an alias to /src
  import AppsList from '@/components/AppsList.vue'

  export default {
    name: 'Home',
    components: {
      AppsList
    }
  }
</script>

<style scoped>
  #presentation h1 {
    font-size: 30px;
    color: #000000;
    margin: 30px 0 20px 0px;
    height: 24px;
    font-weight: bold;
  }

  #presentation h2 {
    font-size: 28px;
    color: #A6AAAD;
    margin: 7px 0 8px 8px;
    width: 710px;
    line-height: 24px;
    font-weight: bold;
  }

  #presentation #about {
    margin: 20px 60px 20px 43px;
    color: var(--text-main-color);
  }

  .line {
    height: 0;
    width: 100%;
    overflow: hidden;
    border-top: 2px solid var(--blue-icra);
  }

</style>
