<template lang="pug">
  #app
    router-view
</template>



<script>
//import json from "../public/data.json"
//import AppInfo from "components/AppInfo";

  export default {
    name: 'App',
    data() {
      return {
        //myJson: json,
        appsInfo: {}
      };
    },
    created: function () {
      /*
      let _this = this;
      let appsList = json['apps'];
      let { routes } =this.$router.options;
      let routeData = routes[0];
      //console.log("route_Data:", routeData);
      appsList.forEach(app => {
        let appName = app.name.replace(/\s/g, "-"); //replace whitespaces with '-'
        _this.appsInfo[appName] = app;
        const path = '/'+appName;
        routeData.children.push({ path: path, name: appName, component: AppInfo, props: {appInfo: app}});
      });
      console.log(routeData);
      this.$router.addRoutes([routeData])*/
      console.log('app created')

    }
  }

</script>


<style>
  @import "https://fonts.googleapis.com/css?family=Lato:400,700";


  :root {
    --font-family-primary: 'Lato', sans-serif;
    --text-main-color: #2F2F2F;
    --text-nav-color: #5c6a72;
    --dark-gray: #5a6870;
    --orange-icra: #ea9e26;
    --blue-icra: #00b8ef;
    --blue-icra-hovered: #008fba;
    --bold-text: 600;
    --text-weight: 400;

  }

  #app {
    max-width: 1200px;
    overflow: hidden;
    margin: 15px auto 15px auto;
    font-family: var(--font-family-primary);
    color: var(--text-nav-color);
    font-weight: var(--text-weight);
    font-size: 14px;
  }




</style>

